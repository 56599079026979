// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../styles/utils.scss";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../styles/private_utils.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7yaAcfzWx{z-index:1}._5Bv7SfI3x{height:auto}.gH7NzdOyx{}", "",{"version":3,"sources":["webpack://client/shared/bottom-bar/bottom-bar.scss"],"names":[],"mappings":"AAEA,YAGE,SAAA,CAGF,YACE,WAAA,CAGF,WAEE","sourcesContent":["@import '../styles/variables.scss';\n\n.bottom-bar {\n  composes: top-divider-inset vertically-center from '../styles/utils.scss';\n  composes: bar from '../styles/private_utils.scss';\n  z-index: 1;\n}\n\n.auto-height {\n  height: auto;\n}\n\n.right {\n  composes: bottom-bar;\n  composes: flex-end from '../styles/utils.scss';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"bottom-bar": "_7yaAcfzWx " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["top-divider-inset"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["vertically-center"] + " " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["bar"] + "",
	"bottomBar": "_7yaAcfzWx " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["top-divider-inset"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["vertically-center"] + " " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["bar"] + "",
	"auto-height": "_5Bv7SfI3x",
	"autoHeight": "_5Bv7SfI3x",
	"right": "gH7NzdOyx _7yaAcfzWx " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["top-divider-inset"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["vertically-center"] + " " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["bar"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["flex-end"] + ""
};
export default ___CSS_LOADER_EXPORT___;
