/**
 * A position on the perimiter of a rectangle
 */
export enum PerimeterPosition {
  TopLeft,
  TopCenter,
  TopRight,
  BottomRight,
  BottomCenter,
  BottomLeft,
  CenterRight,
  CenterLeft,
  CenterCenter,
  FloatingLeft,
  FloatingCenter,
  FloatingRight,
}
