import { isSemVerFormat } from "../utils/version-numbers/version-helpers";

const isExisty = (value: any) => value !== null && value !== undefined;

const isEmpty = (value: any) => {
  return value === "";
};

const validations = {
  isDefaultRequiredValue(values: any, value: any) {
    return value === undefined || value === "";
  },
  isExisty(values: any, value: any) {
    return isExisty(value);
  },
  matchRegexp(values: any, value: any, regexp: any) {
    return !isExisty(value) || isEmpty(value) || regexp.test(value);
  },
  isUndefined(values: any, value: any) {
    return value === undefined;
  },
  isEmptyString(values: any, value: any) {
    return isEmpty(value);
  },
  isEmail(values: any, value: any) {
    return validations.matchRegexp(
      values,
      value,
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
    );
  },
  isUrl(values: any, value: any) {
    return validations.matchRegexp(
      values,
      value,
      /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
    );
  },
  isUuid(values: any, value: any) {
    return validations.matchRegexp(values, value, /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
  },
  isTrue(values: any, value: any) {
    return value === true;
  },
  isFalse(values: any, value: any) {
    return value === false;
  },
  isNumeric(values: any, value: any) {
    if (typeof value === "number") {
      return true;
    }
    return validations.matchRegexp(values, value, /^[-+]?(\d*[.])?\d+$/);
  },
  isAlpha(values: any, value: any) {
    return validations.matchRegexp(values, value, /^[a-zA-Z]+$/);
  },
  isWords(values: any, value: any) {
    return validations.matchRegexp(values, value, /^[a-zA-Z\s]+$/);
  },
  isSpecialWords(values: any, value: any) {
    return validations.matchRegexp(values, value, /^[a-zA-Z\s\u00C0-\u017F]+$/);
  },
  isLength(values: any, value: any, length: any) {
    return !isExisty(value) || isEmpty(value) || value.length === length;
  },
  equals(values: any, value: any, eql: any) {
    return !isExisty(value) || isEmpty(value) || value === eql;
  },
  equalsField(values: any, value: any, field: any) {
    return value === values[field];
  },
  maxLength(values: any, value: any, length: any) {
    return !isExisty(value) || value.length <= length;
  },
  minLength(values: any, value: any, length: any) {
    return !isExisty(value) || isEmpty(value) || value.length >= length;
  },
  isSemVersion(values: any, value: any) {
    return isSemVerFormat(value);
  },
};

export default validations;
