import * as React from "react";
import { observer } from "mobx-react";
import { MessageBar, MessageBarProps } from "../message-bar";
import { Text, Size } from "../typography";
import { BillingNotifierBase } from "@root/data/shell/utils/billing-notifiers/types";
import { NotificationType } from "@lib/common-interfaces";

export interface BillingNotificationBoardProps {
  notifier: BillingNotifierBase;
  canShow: boolean;
  className?: string;
}

@observer
export class BillingNotificationBoard extends React.Component<BillingNotificationBoardProps, {}> {
  public UNSAFE_componentWillMount() {
    this.props.notifier.restartPolling();
  }

  public render() {
    const { notifier, canShow, ...passthrough } = this.props;

    if (!notifier.notification) {
      return null;
    }

    const { notification } = notifier;
    const { type, message, notificationAction } = notification;
    const { title, description } = message;
    const action: Partial<MessageBarProps> = notificationAction
      ? {
          onAction: notificationAction,
          actionButtonText: type === NotificationType.Warning ? "View plan details" : "View plan options",
        }
      : {};

    return canShow ? (
      <MessageBar type={type} {...action} {...passthrough}>
        {title ? (
          <Text tagName="div" size={Size.Large}>
            {title}
          </Text>
        ) : null}
        <Text size={Size.Medium}>{description}</Text>
      </MessageBar>
    ) : null;
  }
}
