/**
 * Standard sizes for avatars and icons
 */
export enum IconSize {
  /** 12px */
  XXSmall,
  /** 16px */
  XSmall,
  /** 20px */
  Small,
  /** 24px */
  XMedium,
  /** 32px */
  Medium,
  /** 48px */
  Large,
  /** 64px */
  XLarge,
}
