// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../styles/utils.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7a6u9MrJx{min-width:520px;width:520px;margin-left:-260px;word-wrap:break-word}@media(max-width: 767px){._7a6u9MrJx{min-width:150px !important;width:calc(100% - (32px * 2)) !important;margin-right:32px !important;margin-left:calc(-50% + 32px) !important}}.ajVNoJ0fx{}._559ILXTDx{display:flex;justify-content:flex-end}", "",{"version":3,"sources":["webpack://client/shared/dialog/confirmation-dialog.scss"],"names":[],"mappings":"AAGA,YACE,eAHM,CAIN,WAJM,CAKN,kBAAA,CACA,oBAAA,CACA,yBALF,YAMI,0BAAA,CACA,wCAAA,CACA,4BAAA,CACA,wCAAA,CAAA,CAIJ,WACE,CAGF,YACE,YAAA,CACA,wBACA","sourcesContent":["@import '../styles/variables.scss';\n$width: 520px;\n\n.dialog {\n  min-width: $width;\n  width: $width;\n  margin-left: -$width * 0.5;\n  word-wrap: break-word;\n  @media (max-width: $phone-screen-max) {\n    min-width: 150px !important;\n    width: calc(100% - (#{$space-l} * 2))!important;\n    margin-right: $space-l !important;\n    margin-left: calc(-50% + #{$space-l}) !important;\n  }\n}\n\n.title {\n  composes: wordwrap from '~@css/utils.scss';\n}\n\n.buttons {\n  display: flex;\n  justify-content: flex-end;\n  composes: space-xl-above from '../styles/utils.scss';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"dialog": "_7a6u9MrJx",
	"title": "ajVNoJ0fx " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["wordwrap"] + "",
	"buttons": "_559ILXTDx " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["space-xl-above"] + ""
};
export default ___CSS_LOADER_EXPORT___;
