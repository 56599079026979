// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(max-width: 767px){html[data-supports-mobile=true] ._17CyIgUfx{border-radius:0;margin-left:-16px;margin-right:-16px;width:auto}}", "",{"version":3,"sources":["webpack://client/shared/card/card.scss"],"names":[],"mappings":"AAEA,yBACE,4CACE,eAAA,CACA,iBAAA,CACA,kBAAA,CACA,UAAA,CAAA","sourcesContent":["@import \"~@css/vars.scss\";\n\n@media (max-width: $phone-screen-max) {\n  html[data-supports-mobile=true] .edge-to-edge {\n    border-radius: 0;\n    margin-left: #{-$primary-padding-mobile};\n    margin-right: #{-$primary-padding-mobile};\n    width: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"edge-to-edge": "_17CyIgUfx",
	"edgeToEdge": "_17CyIgUfx"
};
export default ___CSS_LOADER_EXPORT___;
