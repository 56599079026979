// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y2BMp4R5x{color:#d94a41;font-size:.9em}", "",{"version":3,"sources":["webpack://client/shared/external-data/external-data-display.scss"],"names":[],"mappings":"AAAA,WACE,aAAA,CACA,cAAA","sourcesContent":[".failed {\n  color: #d94a41;\n  font-size: 0.9em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"failed": "y2BMp4R5x"
};
export default ___CSS_LOADER_EXPORT___;
