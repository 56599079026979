// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../styles/utils.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ay1idSJx{cursor:pointer}@supports(-moz-appearance: meterbar) and (all: initial){._1ay1idSJx:focus{-moz-outline-radius:2px;outline:2px solid #3f9ae4;outline-offset:1px}}._1xhhPWXMx{cursor:default;pointer-events:none}", "",{"version":3,"sources":["webpack://client/shared/action-text/action-text.scss"],"names":[],"mappings":"AAEA,YAEE,cAAA,CAIF,wDACE,kBACE,uBAAA,CACA,yBAAA,CACA,kBAAA,CAAA,CAIJ,YAEE,cAAA,CACA,mBAAA","sourcesContent":["@import '~@css/vars.scss';\n\n.actionText {\n  composes: unbutton from '~@css/utils.scss';\n  cursor: pointer;\n}\n\n// Firefox: Override default browser outline\n@supports (-moz-appearance:meterbar) and (all:initial) {\n  .actionText:focus {\n    -moz-outline-radius: 2px;\n    outline: 2px solid $azure-light1;\n    outline-offset: 1px;\n  }\n}\n\n.disabled {\n  composes: actionText;\n  cursor: default;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"actionText": "_1ay1idSJx " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["unbutton"] + "",
	"disabled": "_1xhhPWXMx _1ay1idSJx " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["unbutton"] + ""
};
export default ___CSS_LOADER_EXPORT___;
